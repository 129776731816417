import './index.css';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as moment from 'moment';
import { Button, Container, Divider, Header, Icon, Segment } from 'semantic-ui-react';

import RadialChart from '../radialChart';

function stateToComponent(state) {
  return {
    who: state.who
  };
}

@connect(stateToComponent)
@withRouter
class HeroNumber extends Component {
  render() {
    const { title, value, icon, color } = this.props;

    const {
      total,
      added,
      removed,
      updated,
      addAction,
      editAction,
      chartItems,
      updatedName
    } = value;

    return (
      <Segment.Group raised>
        <Segment attached="top" color={color}>
          <Header.Subheader>
            <Icon name={icon} /> {title}
          </Header.Subheader>
        </Segment>
        <Segment attached className="dashboard-tile basic dashboard-profile-metric">
          {chartItems ? <RadialChart size={80} items={chartItems} /> : null}

          <Container className="dashboard-profile-changes">
            <Header as="h2">{total}</Header>
            <p>
              <Icon color="green" size="small" name="arrow up" />
              {`+${added} this week`}
            </p>
            <p>
              <Icon color="red" size="small" name="arrow down" />
              {`-${removed} this month`}
            </p>
          </Container>
          <Container>
            <Header sub>Recently Updated</Header>
            <a href="" onClick={editAction && updated ? editAction(updated._id) : () => {}}>
              {updatedName}
            </a>
            <br />
            <small>{updated ? moment(updated.updatedAt).fromNow() : ''}</small>
            <Divider />
          </Container>
        </Segment>
        <Segment attached="bottom" clearing>
          {addAction ? (
            <Button icon floated="right" onClick={addAction}>
              <Icon name="add" />
            </Button>
          ) : null}
        </Segment>
      </Segment.Group>
    );
  }
}
export default HeroNumber;
